@import "src/styles/variables.scss";

.areYouEligible {
  background: linear-gradient(
    90deg,
    rgba(22, 39, 112, 0.06) -3.98%,
    rgba(56, 35, 97, 0.06) 19.05%,
    rgba(138, 53, 125, 0.06) 40.57%,
    rgba(230, 73, 150, 0.06) 63.91%,
    rgba(235, 70, 61, 0.06) 85.43%,
    rgba(237, 101, 80, 0.06) 110%
  ) !important;
  .commonHeading {
    span.eligibilityTextGradient {
      background: linear-gradient(90deg, #8a357d 40.57%, #e64996 63.91%);

      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    span.small {
      font-size: 34px;
      text-transform: none;
    }
  }
  .criteriaBoxWrap {
    padding: 30px 40px;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0px 4px 18px 0px #0000000f;
    gap: 39px;
    .criteriaBox {
      min-width: 248px;
      flex: 1;
      .step {
        width: 60px;
        height: 60px;
        margin-bottom: 16px;
        padding: 1px;
        border-radius: 500px;
        background: linear-gradient(
          90deg,
          #162770 -3.98%,
          #382361 19.05%,
          #8a357d 40.57%,
          #e64996 63.91%,
          #eb463d 85.43%,
          #ed6550 110%
        );
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-size: 20px;
          display: block;
          width: 100%;
          height: 100%;
          background: #fff;
          border-radius: 500px;
          text-align: center;
          font-weight: 600;
          line-height: 60px;
        }
      }
      h3 {
        color: #db327c;
        font-size: 20px;
        margin: 0;
        margin-bottom: 12px;
        font-weight: 600;
      }
      p {
        font-size: 16px;
        margin: 0;
        line-height: 25px;
      }
    }
  }
}

@media (max-width: 992px) {
  .areYouEligible {
    .commonHeading {
      span.small {
        font-size: 20px;
      }
    }
  }
}
