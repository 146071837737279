@import "/src/styles/variables.scss";

.header {
  padding: 16px 0;
  border-bottom: 1px solid $color-gray-3;
  position: fixed;
  width: 100%;
  background: #fff;
  transition: all 0.5s ease;
  top: 0;
  z-index: 999;

  &Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &Section {
    display: flex;
    gap: 80px;
    height: 47px;
  }

  &Logo {
    width: auto;
    height: 47px;
    display: flex;
    gap: 30px;
    cursor: pointer;
    img {
      height: 100%;
    }
  }

  &Links {
    display: flex;
    gap: 36px;
    align-items: center;
    cursor: pointer;

    li {
      .isActive {
        background: linear-gradient(
          92deg,
          #181b4e -34.44%,
          #9b3581 25.39%,
          #e84680 70%,
          #ec614e 130.02%
        );
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        font-weight: 500;
      }
      a {
        &:hover {
          color: $color-Pink-0;
          background: $color-Pink-0;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          svg {
            path {
              //TODO stroke: url(#paint0_linear_1954_277);
              stroke: $color-Pink-0;
            }
          }
        }
      }

      a {
        color: $color-black-0;
        @include font-reg($fs: 1.4rem);
        background-image: linear-gradient(#000, #000);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        svg {
          path {
            stroke: #000;
          }
        }
      }
    }
  }

  &Wrapper {
    display: flex;
    gap: 36px;
    align-items: center;
  }

  // dropdown
  &SpecialText {
    @include font-med(1.4rem);
    color: var(--gray-shade-2, #888);
    margin-bottom: 10px;
  }

  .linear {
    padding: 1px;
    border-radius: 10px;
    width: 100%;
    border-radius: 6px;

    .active {
      color: $linear-gradient-3;
      background: $color-black-0 !important;
      -webkit-background-clip: text !important;
      background-clip: text !important;
      color: transparent !important;
    }

    &:hover {
      background: $linear-gradient-3;

      a {
        background: $color-black-0 !important;
        -webkit-background-clip: text !important;
        background-clip: text !important;
        color: transparent !important;
      }
    }
  }
  .dropDownActive {
    background: $linear-gradient-3 !important;
  }

  &Character {
    border-radius: 6px;
    border: 1px solid var(--gray-shade-3, #e8e8e4);
    background: #fafafa;
    box-shadow: 0px 16px 36px 0px rgba(0, 0, 0, 0.07);
    padding: 32px;
    @include d-flex();
    align-items: normal;
    gap: 28px;
  }

  &Resources {
    @include d-flex();
    flex-direction: column;
    padding: 20px;
    gap: 14px;
  }

  &TextContent {
    @include d-flex();
    align-items: normal;
    gap: 14px;
    border-radius: 6px;
    border: 1px solid var(--gray-shade-4, #f0f0f0);
    background: #fff;
    padding: 14px;
    cursor: pointer;
    width: 366px;
  }

  &Text {
    @include d-flex();
    align-items: inherit;
    flex-direction: column;
    gap: 10px;

    &:first-of-type {
      gap: 0;
    }
  }

  &HeadingText {
    @include font-semi-bold(1.6rem);
    color: var(--Text, #222529);
  }

  &SubHeading {
    color: var(--gray-shade-2, #888);
    @include font-reg(1.2rem);
    line-height: 150%;
    cursor: pointer;
  }

  &GradientClass {
    color: var(--gray-shade-2, #888);
    @include font-reg(1.2rem);
    line-height: 150%;
    background: $linear-gradient-21;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include d-flex();
    gap: 10px;
    margin-top: 8px;

    &:hover {
      svg {
        transform: translate(2px);
        transition: all 0.8s ease;
      }
    }

    svg {
      transition: all 0.5s ease;
    }
  }

  .headerDropDown {
    position: relative;

    &Data {
      position: absolute;
      width: max-content;
      margin-top: 20px;
      z-index: 999;
      left: -200%;

      &::before {
        content: "";
        height: 30px;
        display: block;
        width: 100%;
        position: absolute;
        left: 0;
        top: -25px;
      }
    }

    &:hover > Data {
      display: block;
      z-index: 1;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
    }

    &List {
      border-radius: 6px;
      background: #fff;
      box-shadow: 0px 6px 40px 0px rgba(90, 38, 77, 0.08);
      padding: 24px;

      li {
        @include font-reg($fs: 1.4rem);
        margin-bottom: 16px;
        border-bottom: 1px solid $color-gray-0;
        padding-bottom: 16px;
        color: $color-black-0;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:last-child {
          margin-bottom: 0;
          border-bottom: none;
          padding-bottom: 0;
        }

        &:hover {
          color: $color-Pink-0;
        }
      }
    }

    ul {
      position: absolute;
      display: none;
      width: max-content;
      margin-top: 20px;

      &::before {
        content: "";
        height: 30px;
        display: block;
        width: 100%;
        position: absolute;
        left: 0;
        top: -25px;
      }
    }

    &:hover > ul {
      display: block;
      z-index: 1;
    }
  }

  &Button {
    &Btn {
      color: $color-white-0;
      @include font-med($fs: 1.4rem);
      background: $linear-gradient-1;
      border-radius: 4px;
      outline: 0;
      padding: 12px 24px;
      border: 0;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      height: 40px;

      &:hover {
        background: $color-darkBlue-1;

        svg {
          transform: translate(6px);
          transition: all 0.8s ease;
        }
      }

      svg {
        transition: all 0.5s ease;
      }
    }

    &Mobile {
      display: none;
    }
  }

  &Hamburger {
    display: none;
  }

  @media (max-width: 875px) {
    padding: 14px 0;

    // dropdown
    &SpecialText {
      @include font-med(1.2rem);
    }

    &Content {
      margin-bottom: 8px;
    }

    &Character {
      padding: 14px 24px;
      flex-direction: column;
      gap: 14px;
    }

    &TextContent {
      gap: 7px;
      padding: 10px;
      width: 100%;
    }

    &Text {
      gap: 0;
      width: 100%;
    }

    &HeadingText {
      @include font-semi-bold(1.4rem);
    }

    &GradientClass {
      gap: 8px;
    }

    &Wrapper {
      flex-direction: column;
      padding: 0 24px;
      width: 100%;
      gap: 0;
    }

    &NewContainer {
      height: 38px;
    }

    &Logo {
      max-height: 38px;

      a {
        img {
          height: 100%;
        }
      }
    }

    &Links {
      position: absolute;
      flex-direction: column;
      width: 100%;
      left: 0;
      top: 67px;
      overflow: hidden;
      height: 0;
      gap: 0;
      z-index: 200;
      background: #fff;
      transition: all 0.3s ease;

      li {
        padding: 18px 0;
        border-bottom: 1px solid $color-gray-3;
        width: 100%;

        &:hover {
          //TODO color: $color-grey-4;
        }
      }

      a {
        width: 100%;
      }
    }

    .headerOpen {
      height: calc(100dvh - 66px);
      display: flex;
      transition: all 0.2s ease 0s;
      width: 100%;
      justify-content: flex-start;
      overflow: auto;
    }

    &Hamburger {
      display: block;
      cursor: pointer;
    }

    &Button {
      &Btn {
        display: none;
      }

      &Mobile {
        display: block;
        color: $color-white-0;
        @include font-med($fs: 1.4rem);
        background: $linear-gradient-1;
        border-radius: 4px;
        outline: 0;
        padding: 12px 24px;
        border: 0;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        width: 100%;
        justify-content: center;

        &:hover {
          background: $color-darkBlue-1;

          svg {
            transform: translate(6px);
            transition: all 0.8s ease;
          }
        }
      }
    }

    .headerDropDown {
      a {
        //TODO display: block;
      }

      &Data {
        position: unset;
        left: 0;
        margin-top: 14px;
      }

      &List {
        box-shadow: none;
        padding: 0;

        li {
          border-bottom: none;
          padding: 18px 0 6px;
          margin: 0;

          &:first-child {
            padding-top: 6px;
          }
        }
      }

      ul {
        position: inherit;
        display: none;
        width: max-content;
        margin-top: 20px;

        &::before {
          content: "";
          height: 0;
          display: block;
          width: 100%;
          position: absolute;
          left: 0;
          top: -25px;
        }
      }

      &:hover > ul {
        display: block;
        z-index: 1;
        width: 100%;
        box-shadow: none;
      }
    }
  }

  &WrapperButton {
    width: 100%;
    text-align: center;
    padding: 0 16px 30px;
    margin-top: auto;
  }
}

.hidden {
  transform: translateY(-250%) !important;
  transition: all 0.5s ease-in-out;
}
