@import "/src/styles/variables.scss";

.whitePaper {
  background: url(../../assets/images/whitePaper/whitePaperBgCard.png),
    $linear-gradient-15 !important;
  background-position: top left !important;
  background-repeat: no-repeat !important;

  h2 {
    max-width: 692px;
    margin: 0 auto;
  }

  &Gradient {
    background: linear-gradient(
      90deg,
      #162770 25.43%,
      #382361 42.52%,
      #8a357d 58.49%,
      #e64996 75.8%,
      #eb463d 91.77%,
      #ed6550 110%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include font-semi-bold(4.4rem);
    line-height: 130%;
    text-transform: capitalize;
  }

  &Cards {
    padding: 100px 0;

    &Title {
      color: $color-black-0;
      @include font-reg($fs: 3.2rem);
      line-height: 130%;
      font-family: EchoesSansMedium;
      text-align: center;
    }

    &Section {
      padding-top: 24px;
      @include d-flex($jc: center);
      gap: 40px;
      align-items: stretch;

      .linear {
        padding: 1px;
        border-radius: 10px;

        &:hover {
          background: $linear-gradient-3;
        }
      }
    }
  }

  &Card {
    @include d-flex($jc: flex-start);
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    background-color: $color-white-0;
    border-radius: 8px;
    cursor: pointer;
    height: 100%;

    p {
      color: $color-black-0;
      @include font-semi-bold($fs: 1.6rem);
      line-height: 160%;
      text-align: center;
      max-width: 176px;
      min-height: 78px;
    }

    .gif {
      display: none;
      max-width: 60px;
    }

    &:hover {
      transition: all 0.5s ease;
      box-shadow: 2px 4px 40px 0px rgba(0, 0, 0, 0.06);

      .image {
        display: none;
      }

      .gif {
        display: block;
      }
    }
  }

  &FormSection {
    @include d-flex($jc: space-between);
    gap: 10px;
  }

  &Details {
    flex-basis: 45.4%;

    h3 {
      text-align: unset;
    }

    p {
      color: $color-grey-0;
      @include font-med($fs: 1.6rem);
      line-height: 150%;
      padding-top: 16px;
    }
  }

  &Form {
    background-color: $color-white-0;
    box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.03);
    padding: 40px;
    border-radius: 4px;
    flex-basis: 41.6%;
    position: relative;

    h3 {
      padding-bottom: 40px;
    }

    form {
      div {
        @include d-flex($jc: center);
        align-items: flex-start;
        flex-direction: column;
        gap: 8px;
        color: red;
        font-size: 1.4rem;
        font-weight: 400;
        margin-top: 8px;

        &:first-child {
          padding-bottom: 16px;
        }

        label {
          color: $color-black-0;
          @include font-med($fs: 1.6rem);
          line-height: 160%;
        }

        input {
          padding: 10px 14px;
          border: 1px solid $color-gray-3;
          border-radius: 4px;
          width: 100%;
          height: 46px;
        }
      }

      button {
        margin-top: 40px;
      }
    }

    &InputError {
      position: absolute;
      bottom: 98px;
    }
  }

  &Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $linear-gradient-2 !important;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    z-index: 999;
  }

  &PopUp {
    border-radius: 8px;
    background: #fff;
    box-shadow: 4px 8px 50px 0px rgba(0, 0, 0, 0.03);
    max-width: 527px;
    margin: 0 auto;
    padding: 56px 64px;
    text-align: center;
    z-index: 2;

    &Text {
      color: $color-black-0;
      @include font-semi-bold(1.6rem);
      line-height: 160%;
    }
  }
}

// media query
@media (max-width: 992px) {
  .whitePaper {
    &Gradient {
      @include font-semi-bold(2.4rem);
    }

    &CardsSection {
      gap: 24px;
    }
  }
}

@media (max-width: 768px) {
  .whitePaper {
    background: $linear-gradient-15 !important;

    &Cards {
      padding: 32px 0;

      &Title {
        font-size: 1.8rem;
      }

      &Section {
        flex-wrap: wrap;
        gap: 16px;

        .linear {
          background: $linear-gradient-3;
        }
      }
    }

    &Card {
      gap: 8px;

      img {
        max-width: 40px !important;
      }

      p {
        font-size: 1.4rem;
        max-width: 114px;
        min-height: 63px;
      }
    }

    &FormSection {
      flex-direction: column;
      align-items: stretch;
      gap: 64px;
    }

    &Form {
      padding: 24px;

      h3 {
        padding-bottom: 24px;
      }

      form {
        div {
          label {
            font-size: 1.4rem;
          }
        }

        button {
          margin-top: 24px;
        }
      }
    }
  }
}
