@import "/src/styles/variables.scss";

.hear {
  padding-top: 80px;
  &Container {
    padding: 72px 0px;
    .headingBox {
      margin-bottom: 40px;

      h2 {
        color: #222529;
        @include font-semi-bold(4.4rem);
        line-height: 130%;
        text-align: center;

        span {
          background: linear-gradient(
            90deg,
            #131d56 43.45%,
            #33145d 54.99%,
            #63166a 65.76%,
            #a4267b 77.45%,
            #db327c 88.22%,
            #eb4b46 100.53%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          @include font-semi-bold(4.4rem);
          line-height: 130%;
        }
      }
    }
    .customersBox {
      @include d-flex($jc: center);
      gap: 24px;
      flex-wrap: wrap;
      margin-bottom: 40px;
    }

    .viewMore {
      @include d-flex($jc: center);
      align-items: center;
      gap: 6px;
      @include font-med($fs: 1.6rem);
      font-size: 16px;
      background: var(
        --Primary-Brand-Gradient,
        linear-gradient(
          90deg,
          #131d56 -1.66%,
          #33145d 26.58%,
          #63166a 53.17%,
          #a4267b 72.03%,
          #db327c 87.36%,
          #eb4b46 103.24%
        )
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &Card {
    padding: 24px;
    max-width: 384px;
    @include d-flex();
    flex-direction: column;
    align-items: start;
    gap: 16px;
    background-color: #fbfbfb;
    align-self: stretch;

    &Top {
      @include d-flex();
      align-items: start;
      flex-direction: column;
      gap: 4px;

      h2 {
        color: var(--Primary-Text, #222529);
        @include font-semi-bold(2rem);
        line-height: 130%;
      }

      h3 {
        color: var(--Primary-Text, #222529);
        @include font-med(1.4rem);
        line-height: 160%;
      }
    }

    p {
      color: var(--Gray-Shade-1, #4e4e4e);
      @include font-med(1.6rem);
      line-height: 150%;
    }
  }
}
