@import "/src/styles/variables.scss";

.ismsPolicy {
  padding: 80px 0 0;

  &Container {
    padding: 56px 0 !important;
  }

  &CommonHeading {
    h1 {
      color: $color-grey-0;
      font-size: 4rem !important;
      text-align: left !important;
      font-family: EchoesSansMedium;
      margin-bottom: 0 !important;
    }
  }

  &Wrapper {
    padding-bottom: 24px;
  }

  li {
    padding-top: 15px;
    color: #4e5779;
    @include font-reg($fs: 1.6rem);
    line-height: 160%;
  }

  div {
    color: #4e5779;
    @include font-reg($fs: 1.6rem);
    line-height: 160%;
  }

  ul {
    padding-left: 32px;
    padding-bottom: 24px;
    list-style-type: disc !important;
  }

  .underline {
    @include font-semi-bold($fs: 1.6rem);
    color: #4e4e4e;
    padding-bottom: 9px;
    display: inline-block;
  }

  @media (max-width: 992px) {
    padding: 67px 0 0px !important;

    &Container {
      padding: 40px 24px !important;
    }

    &CommonHeading {
      h1 {
        font-size: 2rem;
        padding-top: 35px;
      }
    }

    li {
      padding-top: 5px;
    }

    ul {
      padding-left: 16px;
    }

    div {
      padding-top: 5px !important;
    }
  }
}
