@import "src/styles/variables.scss";

.amazonHowWorks {
  padding: 80px 0;
  background: $color-gray-5;

  &Container {
    @include d-flex();
    align-items: center;
    flex-direction: column;
    gap: 95px;

    .videoBox {
      @include d-flex($jc: center);
      flex-wrap: wrap;
      gap: 40px;
      width: 100%;
      // flex-direction: column;

      &Header {
        margin-bottom: 0;
        h1 {
          margin-bottom: 20px;
          color: var(--Primary-Text, #222529);
          @include font-semi-bold($fs: 4.4rem);
          line-height: 130%;
          text-transform: capitalize;
          span {
            background: linear-gradient(
              91deg,
              #162770 35.69%,
              #382361 53.3%,
              #8a357d 69.75%,
              #e64996 87.59%,
              #eb463d 104.04%,
              #ed6550 122.83%
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            @include font-semi-bold($fs: 4.4rem);
            line-height: 130%;
            text-transform: capitalize;
          }
        }

        p {
          color: var(--Gray-Shade-1, #4e4e4e);
          font-family: EchoesSansMedium;
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%;
        }
      }

      .cardParent {
        max-width: 100%;
        @include d-flex();
        gap: 40px;
        flex-wrap: wrap;
        position: relative;

        //   .disabled {
        //     pointer-events: none;
        //     cursor: not-allowed;
        //   }

        //   .slick-track {
        //     display: flex;

        //     .slick-slide:not(:first-child) {
        //       margin-left: 40px;
        //     }
        //   }

        //   .slick-slider {
        //     width: 100%;
        //     height: fit-content;
        //     padding-bottom: 60px;
        //   }

        //   .slick-next {
        //     right: 46%;
        //     top: 95%;
        //     border-radius: 50%;
        //     width: 40px;
        //     height: 40px;
        //   }
        //   .slick-prev {
        //     left: 46%;
        //     top: 95%;
        //     border-radius: 50%;
        //     width: 40px;
        //     height: 40px;
        //   }
      }

      &Card {
        max-width: 580px;
        height: 390px;
        flex: 0 0 auto;
        width: 50%;
        box-sizing: border-box;
        cursor: pointer;
        border-radius: 12px;
        overflow: hidden;
        align-self: stretch;

        h1 {
          margin-bottom: 4px;
          color: var(--Primary-Text, #222529);
          font-family: EchoesSansMedium;
          @include font-med($fs: 2.4rem);
          line-height: 130%;
        }
        p {
          color: var(--Gray-Shade-1, #4e4e4e);
          font-family: Montserrat;
          @include font-reg($fs: 1.6rem);
          line-height: 150%;
        }

        .imgvideoBox {
          position: relative;
          height: 75.9162%;

          img {
            width: 100%;
            height: 100%;
          }

          .playVedio {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
          }
        }

        .infoButtonBox {
          @include d-flex($jc: space-between);
          width: 100%;
          padding: 20px;
          background: #fff;
          box-shadow: 0px 4px 22.3px 0px rgba(230, 230, 230, 0.25);
          height: 24.0838% !important;

          .left {
            flex-basis: 94%;

            h1 {
              color: var(--Primary-Text, #222529);
              font-family: EchoesSansMedium;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 130%;
            }
          }

          .right {
            flex-basis: 6.8966%;
            @include d-flex($jc: end);
          }
        }
      }

      .leftRightBox {
        @include d-flex();
        gap: 16px;

        .arrowBox {
          height: 40px;
          width: 40px;
          @include d-flex($jc: center);
          //  padding: 9.41px;
          border-radius: 37.647px;
          border: 1px solid #e8e8e4;

          .common {
            transform: rotate(180deg);
          }

          .gradi {
            transform: rotate(-180deg);
          }
        }

        .activeArrow {
          border: 1px solid #19147b;
        }
      }

      .viewMore {
        font-family: Montserrat;
        @include font-med($fs: 1.6rem);
        line-height: 150%;
        font-family: Montserrat;
        background: var(
          --Primary-Brand-Gradient,
          linear-gradient(
            90deg,
            #131d56 -1.66%,
            #33145d 26.58%,
            #63166a 53.17%,
            #a4267b 72.03%,
            #db327c 87.36%,
            #eb4b46 103.24%
          )
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .vedioModal {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1002;
      background-color: rgba(1, 1, 1, 0.7);
      @include d-flex(center);
      align-items: center;

      .modal {
        .vedioBox {
          position: relative;
          width: 1200px;
          background-color: rgba(1, 1, 1, 0.7);
          height: 600px;

          .close {
            position: absolute;
            top: -5px;
            right: -36px;
            cursor: pointer;
          }
        }
      }
    }

    .tiktokVideosBox {
      @include d-flex();
      // align-items: start;
      gap: 20px;

      &Card {
        img {
          cursor: pointer;

          scale: 0.8;
        }

        &:nth-child(2) {
          img {
            scale: 1;
          }
        }
      }
    }
  }

  &Heading {
    text-align: inherit !important;
    margin-bottom: inherit !important;
  }

  &Content {
    @include d-flex();
    align-items: start;
    flex-direction: column;
    gap: 20px;
    //  max-width: 490px;

    h2 {
      width: 100%;
      @include d-flex($jc: center);
    }

    p {
      font-family: EchoesSansMedium;
      font-size: 32px;
      line-height: 130%;
      color: #4e4e4e;
    }
  }

  &Progress {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.92) 0%,
        rgba(255, 255, 255, 0.92) 100%
      ),
      linear-gradient(
        106deg,
        #131d56 -7.8%,
        #33145d 22.01%,
        #63166a 50.09%,
        #a4267b 70.01%,
        #db327c 86.2%,
        #eb4b46 102.96%
      );
    border-radius: 8px;
    padding: 24px;
    position: relative;
    @include d-flex($jc: flex-start);
    align-items: inherit;
    flex-direction: column;
    gap: 24px;

    &Wrapper {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      position: relative;
      z-index: 2;
      // height: 131px;
    }

    .activeItem {
      &:not(:last-of-type) {
        &::after {
          content: "";
          width: 2px;
          background-color: #9b3581;
          display: block;
          position: absolute;
          top: 26px;
          left: 20px;
          animation: progressLine 4s linear;
          animation-fill-mode: forwards;
          z-index: 1;
        }
      }
    }

    &Number {
      z-index: 2;
    }

    &Contents {
      opacity: 0.5;
      cursor: pointer;
      // padding-bottom: 24px;

      h5 {
        color: $color-black-0;
        @include font-semi-bold($fs: 2rem);
        padding-top: 12px;
      }

      &ParaGraph {
        color: $color-black-0;
        line-height: 160%;
        @include font-reg($fs: 1.6rem);
        padding: 14px 0 24px;
        max-width: 542px;
      }

      p {
        color: $color-black-0;
        line-height: 160%;
        @include font-reg($fs: 1.6rem);
        padding: 14px 0 0px;
        max-width: 542px;
        span {
          a {
            color: $color-darkBlue-1;
          }
        }
      }

      &Para {
        padding-bottom: 0 !important;
      }
    }
  }

  .activeTitle {
    opacity: 1;
  }

  @media (max-width: 1186px) {
    padding: 40px 0;

    &Container {
      flex-direction: column;
      gap: 40px;

      .videoBox {
        flex-direction: column !important;

        //   .cardParent {
        //     flex-direction: column;
        //   }
      }
    }

    &Content {
      max-width: 100%;
    }

    &Heading {
      text-align: center !important;
    }

    &Content {
      align-items: center;
      p {
        font-size: 20px;
        padding: 16px 0 16px;
        text-align: center;
      }
    }

    &Progress {
      &Wrapper {
        gap: 16px;
        align-items: unset;
        height: inherit;
      }

      &Number {
        img {
          min-width: 30px;
        }
      }

      .activeItem {
        &:not(:last-of-type) {
          &::after {
            content: "";
            width: 2px;
            display: block;
            position: absolute;
            top: 26px;
            left: 19px;
            animation: progressLine 4s linear;
            animation-fill-mode: forwards;
            z-index: 1;
          }
        }
      }

      &Contents {
        h5 {
          @include font-semi-bold($fs: 1.6rem);
          padding-top: 6px;
        }

        p {
          @include font-reg($fs: 1.4rem);
          padding: 8px 0 5px;
          max-width: 100%;
        }
      }
    }
  }

  @media (max-width: 400px) {
    .activeItem {
      &:not(:last-of-type) {
        &::after {
          content: "";
          width: 2px;
          display: block;
          position: absolute;
          top: 25px;
          left: 14px;
          animation: progressLine 4s linear;
          animation-fill-mode: forwards;
          z-index: 1;
        }
      }
    }
  }

  @media (max-width: 768px) {
    &Container {
      .videoBox {
        flex-direction: column !important;

        &Header {
          h1 {
            @include font-semi-bold($fs: 2.4rem);

            span {
              @include font-semi-bold($fs: 2.4rem);
            }
          }

          p {
            @include font-med($fs: 2rem);
          }
        }

        //   .cardParent {
        //     .slick-next {
        //       right: 35%;
        //       top: 90%;
        //       border-radius: 50%;
        //       width: 40px;
        //       height: 40px;
        //     }
        //     .slick-prev {
        //       left: 35%;
        //       top: 90%;
        //       border-radius: 50%;
        //       width: 40px;
        //       height: 40px;
        //     }

        //     .slick-track {
        //       display: flex;

        //       .slick-slide:not(:first-child) {
        //         margin-left: 10px;
        //       }
        //     }
        //   }

        &Card {
          //  width: 312px;
          //  height: 277.062px !important;
          width: 100%;
          height: 200px;

          .infoButtonBox {
            padding: 10px;

            .left {
              h1 {
                font-size: 14px;
              }
            }
          }
        }
      }

      .vedioModal {
        .modal {
          .vedioBox {
            position: relative;
            max-width: 312px;
            background-color: rgba(1, 1, 1, 0.7);
            height: 206px;

            .close {
              position: absolute;
              top: -34px;
              right: -1px;
              cursor: pointer;
            }
          }
        }
      }

      .tiktokVideosBox {
        flex-direction: column;
        gap: 0;

        &Card {
          //  &:nth-child(2) {
          //    scale: 1;
          //  }
          &:nth-child(2) {
            img {
              scale: 0.8;
            }
          }
        }
      }
    }
  }
}
