@import "src/styles/variables.scss";

.tikTokShop {
  position: relative;

  .sectionsNav {
    z-index: 1001;
    padding: 28px 0px;

    background: linear-gradient(
        85deg,
        rgba(22, 39, 112, 0.06) -9.75%,
        rgba(56, 35, 97, 0.06) 16.28%,
        rgba(138, 53, 125, 0.06) 40.6%,
        rgba(230, 73, 150, 0.06) 66.98%,
        rgba(235, 70, 61, 0.06) 91.3%,
        rgba(237, 101, 80, 0.06) 119.07%
      ),
      var(--Color, #fff);

    .buttonsBox {
      @include d-flex(space-between);
      max-width: 1200px;
      margin: 0 auto;

      button {
        padding: 12px 36px;
        @include d-flex();
        align-items: center;
        border-radius: 56px;
        outline: none;
        border: none;
        gap: 12px;
        color: var(--Gray-Shade-1, #4e4e4e);
        @include font-med($fs: 1.6rem);
        line-height: 130%;
        transition: all 0.3s linear;
        background-color: #fff;
        cursor: pointer;

        img {
          width: 24px;
          height: 24px;
        }

        &.isSpecial {
          border: 1px solid #ed0056;
          span {
            background: linear-gradient(
              90deg,
              #162770 5.29%,
              #382361 22.64%,
              #8a357d 38.85%,
              #e64996 56.42%,
              #eb463d 72.63%,
              #ed6550 91.14%
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      .active {
        color: #fff;
        background: var(--Primary-Primary, #19147b);
      }
    }
  }

  .sticky {
    margin: 0 auto;
    position: fixed;
    width: 100%;
    top: 0px;
    margin: 0 auto;
  }

  @media (max-width: 568px) {
    .sectionsNav {
      display: none;
    }
  }
}
