@import "src/styles/variables.scss";

.howItWorks {
  background: $linear-gradient-10 !important;

  &Study {
    background: $color-white-0 !important;
  }

  &SellerContent {
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    color: $color-grey-0;
  }

  &SellerNotice {
    position: absolute;
    bottom: 0;
  }

  &Steps {
    @include d-flex($jc: flex-start);
    flex-direction: column;
    padding-top: 32px;
    gap: 147px;

    &Height {
      padding-top: 0;
    }
  }

  &Step {
    @include d-flex($jc: flex-start);
    gap: 56px;
    position: relative;

    &::after {
      content: url(../../assets/images/howItWorks/evenDivider.png);
      position: absolute;
      bottom: -147px;
      height: 147px;
      left: 0;
      right: 0;
      width: fit-content;
      margin: 0 auto;
    }

    &:nth-child(odd) {
      &::after {
        content: url(../../assets/images/howItWorks/oddDivider.png);
      }
    }

    &:last-child {
      &::after {
        content: none !important;
      }
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      &::after {
        content: url(../../assets/images/howItWorks/evenDivider.png);
      }
    }

    &Img {
      flex-basis: 50%;
    }

    &Details {
      flex-basis: 50%;

      &ParaColor {
        color: $color-black-0 !important;
      }

      &Color {
        color: $color-black-0 !important;
        @include font-semi-bold(4.4rem !important);
        font-weight: 600 !important;
        line-height: 130% !important;
        font-family: Montserrat !important;
        padding: 0 0 16px !important;
      }

      &SpecialText {
        background: linear-gradient(
          90deg,
          #162770 25.79%,
          #382361 42.8%,
          #8a357d 58.7%,
          #e64996 75.95%,
          #eb463d 91.84%,
          #ed6550 110%
        ) !important;
        background-clip: text;
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
      }

      h3 {
        color: $color-black-0;
        @include font-med($fs: 3.2rem);
        line-height: 130%;
        padding: 32px 0 12px;
        font-family: EchoesSansMedium;

        img {
          display: none;
        }
      }

      p {
        color: $color-grey-0;
        @include font-reg($fs: 1.6rem);
        line-height: 160%;
        padding-bottom: 16px;
      }
    }
  }
}

// media query
@media (max-width: 992px) {
  .howItWorks {
    &Details {
      &Color {
        @include font-semi-bold(2.4rem !important);
      }
    }
  }
}

@media (max-width: 768px) {
  .howItWorks {
    &Steps {
      padding-top: 24px;
      gap: 32px;
    }

    &Step {
      flex-direction: column-reverse;
      gap: 24px;

      &::after {
        content: none !important;
      }

      &:nth-child(even) {
        flex-direction: column-reverse;
      }

      &Details {
        .stepNum {
          display: none;
        }

        &Color {
          @include font-semi-bold(2.4rem !important);

          img {
            display: none !important;
          }
        }

        h3 {
          font-size: 1.8rem;
          padding: 0 0 12px;
          @include d-flex($jc: flex-start);
          gap: 8px;
          justify-content: center;

          img {
            display: block;
            max-width: 20px;
          }
        }

        p {
          font-size: 1.4rem;
          text-align: center;
          line-height: 150%;
        }
      }
    }
  }
}
