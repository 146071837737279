@import "src/styles/variables.scss";

.certificateContainer {
  padding: 68px 20 !important;
  @include d-flex();
  flex-direction: column;
  gap: 24px;

  .isoContainer {
    @include d-flex();
    align-items: stretch;
    gap: 24px;

    .isoBanner {
      max-width: 362px;
      width: 100%;
      @include d-flex($jc: center);
      align-items: center;
      padding: 10px;
      border-radius: 10px;
      border: 1px solid #e7e2f2;
      background: rgba(50, 31, 86, 0.05);
    }

    .sfaBanner {
      max-width: 362px;
      width: 100%;
      @include d-flex($jc: center);
      align-items: center;
      padding: 10px;
      border-radius: 10px;
      border: 1px solid #eacae5;
      background: rgba(164, 38, 123, 0.1);
    }

    .isoContent {
      cursor: pointer;
      border-radius: 10px;
      padding: 20px 24px;
      @include d-flex($jc: center);
      flex-direction: column;
      align-items: self-start;
      gap: 16px;
      background: #321f56;

      .certificateHeading {
        max-width: 704px;
        letter-spacing: 0.64px;
        @include font-semi-bold(3.2rem);
        line-height: 130%;
        color: $color-white-0;
        font-family: Montserrat;
      }

      .certificateContent {
        @include font-reg(1.8rem);
        color: $color-white-0;
        line-height: 150%;
      }

      .isoButton {
        background: #321f56 !important;
        border: 1px solid #ffffff;
        border-radius: 4px;
      }

      .sfaButton {
        background: #762d6b !important;
        border: 1px solid #ffffff;
        border-radius: 4px;
      }
    }

    .sfaContent {
      background: #762d6b;

      .sfaHeading {
        max-width: 774px;
        font-weight: 700;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .certificateContainer {
    .isoContainer {
      .isoBanner {
        max-width: 100%;
      }
      .sfaBanner {
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .certificateContainer {
    padding: 0 !important;
    gap: 14px;

    .isoContainer {
      gap: 14px;
      max-width: 100%;

      .isoBanner {
        max-width: 100%;
        align-items: center;
        padding: 20px;
      }

      .sfaBanner {
        max-width: 100%;
        padding: 20px;
      }

      .isoContent {
        gap: 18px;

        .certificateHeading {
          @include font-semi-bold(2rem);
        }

        .certificateContent {
          @include font-reg(1.4rem);
        }
      }
    }
  }
}
