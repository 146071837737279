@import "src/styles/variables.scss";

.downloadForm {
  padding: 24px;
  width: fit-content;
  position: relative;
  border-radius: 8px;
  border: 1px solid #d7d7d7;
  background: #fff;
  box-shadow: 0px 0px 60px -16px rgba(0, 0, 0, 0.33);
  max-width: 360px;

  &Head {
    //  gap: 49px;
    @include d-flex();
    align-items: flex-start;
    margin-bottom: 26px;
  }

  h3 {
    color: #19147b;
    @include font-med(2.2rem);
    line-height: 130%;
    max-width: 338px;
    text-align: left;
  }

  img {
    cursor: pointer;
  }

  form {
    div {
      @include d-flex($jc: center);
      align-items: flex-start;
      flex-direction: column;
      gap: 8px;
      color: red;
      font-size: 1.4rem;
      font-weight: 400;
      margin-top: 8px;

      &:first-child {
        padding-bottom: 8px;
      }

      label {
        color: $color-black-0;
        @include font-med($fs: 1.6rem);
        line-height: 160%;

        span {
          color: #f00;
          @include font-med($fs: 1.4rem);
          // padding: 0 0 0 6px;
        }
      }

      input {
        padding: 10px 14px;
        border: 1px solid $color-gray-3;
        border-radius: 4px;
        width: 100%;
        height: 46px;
      }
    }

    button {
      margin-top: 40px;
    }
  }

  &InputError {
    position: absolute;
    bottom: 82px;
  }

  &Title {
    color: $color-black-0;
    @include font-reg($fs: 3.2rem);
    line-height: 130%;
    font-family: EchoesSansMedium;
    text-align: center;
  }

  &Section {
    padding-top: 24px;
    @include d-flex($jc: center);
    gap: 40px;
    align-items: stretch;

    .linear {
      padding: 1px;
      border-radius: 10px;

      &:hover {
        background: $linear-gradient-3;
      }
    }
  }

  &Modal {
    position: fixed;
    right: 24px;
    bottom: 24px;
    width: 100%;
    // height: 100%;
    background: $linear-gradient-2 !important;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    align-items: center;
    z-index: 999;
  }

  &PopUp {
    border-radius: 8px;
    background: #fff;
    box-shadow: 4px 8px 50px 0px rgba(0, 0, 0, 0.03);
    max-width: 527px;
    // margin: 0 auto;
    padding: 56px 64px;
    text-align: center;
    z-index: 2;
    // float: right;

    &Text {
      color: $color-black-0;
      @include font-semi-bold(1.6rem);
      line-height: 160%;
    }
  }
}

.activeWhitePaper {
  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: 9;
}

@media (max-width: 992px) {
  .downloadForm {
    padding: 24px;

    h3 {
      padding: 0 0 12px 0;
      @include font-med(1.8rem);
    }

    &Head {
      margin-bottom: 16px;
      gap: 0;
      justify-content: space-between;
    }

    &Title {
      @include font-reg($fs: 2.4rem);
    }
  }
}

@media (max-width: 768px) {
  .activeWhitePaper {
    left: 22px;
    right: 22px;
    width: auto;
  }
}
