@import "src/styles/variables.scss";
@import "src/styles/common.scss";

.footer {
  background: #f9f9f9;
  padding: 80px 0 40px;

  //   parentClass
  &Container {
    @include d-flex();
    gap: 90px;
    align-items: flex-start;
  }

  p {
    color: var(--gray-shade-1, $color-grey-0);
    @include font-reg(1.6rem);
    line-height: 26px;
    width: 287.446px;
    margin: 16px 0 0;
  }

  &SocialIcon {
    @include d-flex();
    margin-top: 40px;
    cursor: pointer;
    gap: 24px;
    transition: all 0.5s ease;

    &Size {
      width: 32px;
      height: 31px;
      background: $color-white-1;
      display: flex;
      justify-content: center;
      transition: all 0.5s ease;
      align-items: center;
      border-radius: 30px;
      border: 1px solid rgba(46, 23, 59, 0.12);
      background: var(--gray-background, #f7f7f7);

      &:hover {
        background: $color-black-1;
        border: none;
      }
    }

    &Logo:hover {
      svg {
        path {
          fill: $color-white-0;
        }
      }
    }
  }

  &Links {
    ul {
      color: var(--Text, $color-black-0);
      @include font-reg(2rem);
      font-family: EchoesSans;
      text-transform: capitalize;
      margin-bottom: 24px;
      width: 134px;
    }

    a {
      color: $color-grey-1;

      &:hover {
        color: $color-darkBlue-0;
      }
    }

    li {
      color: var(--gray-shade-2, $color-grey-1);
      @include font-reg(1.6rem);
      line-height: 25.6px;
      list-style-type: none;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        color: $color-darkBlue-0;
      }
    }

    &Insights {
      width: 192px;
    }
  }

  &Policy {
    @include d-flex(center);
    gap: 40px;
    border-top: 1px solid $color-grey-2;
    margin-top: 40px;

    h4 {
      color: var(--gray-shade-2, $color-grey-1);
      @include font-reg(1.6rem);
      margin-top: 40px;
    }

    h5 {
      color: var(--gray-shade-2, $color-grey-1);
      @include font-reg(1.4rem);
      margin-top: 40px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        text-underline-offset: 2px;
        text-decoration-color: $color-darkBlue-0;
      }
    }
  }

  //   responsive
  @media (max-width: 992px) {
    padding: 40px 0;

    &Container {
      flex-direction: column;
      gap: 0;
    }

    p {
      @include font-reg(1.4rem);
      max-width: 100%;
      margin: 16px 0 24px;
    }

    &SocialIcon {
      @include d-flex();
      margin: 0 0 32px;
      gap: 24px;
    }

    &Links {
      ul {
        @include font-reg(1.8rem);
        margin-bottom: 24px;
      }

      li {
        @include font-reg(1.4rem);
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 24px;
        }

        &:hover {
          color: $color-darkBlue-0;
        }
      }

      &Insights {
        max-width: 100%;
      }
    }

    &Logo {
      img {
        max-width: 67px;
      }
    }

    &Policy {
      flex-direction: column;
      gap: 16px;
      margin-top: 0;

      h4 {
        @include font-reg(1.5rem);
        margin-top: 24px;
      }

      h5 {
        margin-top: 0;
      }
    }
  }
}

.go-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 8px;
  border-radius: 50%;
  background: #d73d84;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 36px;
  height: 36px;
}

@media screen and (max-width: 716px) {
  .CookieConsent {
    padding: 24px 0 !important;
    height: 180px !important;
    justify-content: center !important;
  }
}

