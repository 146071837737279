@import "/src/styles/variables.scss";

.bannerData {
  margin-top: 80px;
  background: $color-white-0;
  border-bottom: 1px solid #e8e8e4;
  padding: 100px 0 !important;

  &SpecialText {
    background: $linear-gradient-6 !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
  }

  .amazonSellerClickingText {
    color: $color-grey-0;
    @include font-reg($fs: 1.4rem);
    line-height: 150%;
    padding-top: 10px;
  }

  .amazonContentText {
    color: #2e3192;
    @include font-med($fs: 1.8rem);
    line-height: 150%;
  }

  .shopByCountry {
    p {
      @include font-med($fs: 1.4rem);
      margin-bottom: 12px;
      line-height: 160%;
      color: #4e4e4e;

      span {
        @include font-bold($fs: 1.4rem);
        color: #4e4e4e;
      }
    }

    .inputButtonBox {
      @include d-flex();
      gap: 16px;
      margin-bottom: 32px;

      .select {
        width: 296px;
        position: relative;
        border: 1px solid #eee;

        .inputArrow {
          @include d-flex();
          height: 100%;
          width: 100%;
          padding-left: 12px;

          input {
            width: 95%;
            height: 100%;
            outline: none;
            border: none;
            padding: 15px 0px 15px 8px;
            cursor: pointer;
            @include font-med($fs: 1.4rem);

            &::placeholder {
              color: #888;
              @include font-med($fs: 1.4rem);
              line-height: 150%;
            }
          }

          .arrowBox {
            height: 100%;
            padding-right: 12px;

            img {
              cursor: pointer;
            }

            &:focus {
              transform: rotate(180deg);
            }
          }
        }

        .options {
          position: absolute;
          width: 100%;
          background-color: #fff;
          z-index: 100;

          box-shadow: 0px 0px 23.6px 0px rgba(146, 146, 146, 0.25);

          .option {
            padding: 15px 12px;
            @include d-flex();
            gap: 8px;
            cursor: pointer;

            span {
              @include font-med($fs: 1.4rem);
              color: #4e4e4e;
            }

            &:hover {
              background: #f8f8f8;
            }
          }
        }
      }

      .disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  &NewImg {
    max-width: 453px !important;
  }

  &Heading {
    max-width: 1114px !important;
  }

  &Class {
    display: block;
  }

  &Header {
    max-width: 890px;
    margin-bottom: 24px;
  }

  &About {
    max-width: 900px !important;
  }

  &Extra {
    max-width: 869px !important;
    color: $color-black-0 !important;
  }

  &Gradient {
    background: $linear-gradient-3 !important;
    background-clip: text;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
  }

  &Grouping {
    @include d-flex(space-between);

    .amazonsellerBox {
      max-width: 440px;
      border-radius: 25.407px;
      overflow: hidden;
      background: linear-gradient(
          85deg,
          rgba(22, 39, 112, 0.04) -9.75%,
          rgba(56, 35, 97, 0.04) 16.28%,
          rgba(138, 53, 125, 0.04) 40.6%,
          rgba(230, 73, 150, 0.04) 66.98%,
          rgba(235, 70, 61, 0.04) 91.3%,
          rgba(237, 101, 80, 0.04) 119.07%
        ),
        #fff;
      box-shadow: 0px 0px 47.9px 8.46px rgba(137, 137, 137, 0.15);

      .giftBox {
        position: relative;

        .promoCode {
          position: absolute;
          left: 21px;
          bottom: 18.25px;
          padding: 10px 16px;
          border-radius: 23.251px;
          background: #fff;
          color: #19147b;
          text-align: center;
          @include font-med($fs: 1.8rem);
          letter-spacing: 0.069px;
        }
      }

      .contentBox {
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 18.48px;

        span {
          color: #222;
          @include font-bold($fs: 1.8rem);
          line-height: 28.871px; /* 156.25% */
        }

        .term {
          color: #19147b;
          leading-trim: both;
          text-edge: cap;
          @include font-med($fs: 1.2rem);
          text-decoration-line: underline;
        }

        .promoCode {
          padding: 16px 24px;
          border-radius: 39.055px;
          background: rgba(25, 20, 123, 0.06);
          color: #19147b;
          text-align: center;
          @include font-med($fs: 1.8rem);
          letter-spacing: 0.069px;
        }

        button {
          cursor: pointer;
          outline: none;
          //  padding: 17.32px 27.72px;
          height: 40px;
          width: 140px;
          border-radius: 4.619px;
          @include d-flex(center);
          gap: 16.31px;
          border: 1.155px solid #131d56;
          leading-trim: both;
          text-edge: cap;
          @include font-med($fs: 1.4rem);
          background: var(
            --Primary-Brand-Gradient,
            linear-gradient(
              90deg,
              #131d56 -1.66%,
              #33145d 26.58%,
              #63166a 53.17%,
              #a4267b 72.03%,
              #db327c 87.36%,
              #eb4b46 103.24%
            )
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          img {
            height: 8px;
            width: 14px;
          }
        }
      }
    }
  }

  &Content {
    @include d-flex();
    align-items: baseline;
    flex-direction: column;
    flex-basis: 52.2%;
  }

  &Heading {
    margin-bottom: 24px;
  }

  &ParaGraph {
    color: $color-grey-0 !important;
    @include font-reg(1.8rem);
    line-height: 150%;
    max-width: 812px;
    margin-bottom: 40px;
  }

  &Image {
    display: flex;
    justify-content: center;
    min-height: 100%;
    align-items: center;
    max-width: 30%;

    img {
      width: 100%;
    }
  }

  &PayLaterDirect {
    max-width: 40%;
  }

  .offerModal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    @include d-flex(center);
    background: rgba(0, 0, 0, 0.65);

    .modal {
      width: 800px;
      max-width: calc(100% - 30px);
      &Top {
        background-image: url("../../assets/images/tikTokShop/popUpBackGround.png");
        background-position: 80%;
        padding: 20px 24px;
        background-size: cover;
        position: relative;

        img {
          position: absolute;
          right: 15px;
          top: 15px;
          cursor: pointer;
        }

        span {
          color: #fff;
          @include font-med($fs: 1.4rem);
          letter-spacing: 0.056px;
        }

        p {
          @include font-semi-bold($fs: 2.1rem);
          color: #fff;
          line-height: 32px;
          letter-spacing: 0.084px;
        }
      }

      &Info {
        padding: 24px;
        background-color: #ffff;

        .infoImagWrapper {
          @include d-flex();

          ul {
            list-style: disc;
            width: 68%;
            padding-left: 24px;
            li {
              color: #222529;
              @include font-med($fs: 1.4rem);
              line-height: 150%;
              margin-bottom: 16px;

              span {
                color: var(--Primary-Text, #222529);
                @include font-bold($fs: 1.4rem);
                font-weight: 700;
                line-height: 24px;
              }
            }
          }

          .imgBox {
            width: 32%;
            align-self: flex-end;
          }

          .offerButton {
            padding: 12px 24px;
            margin-left: 0;
          }
        }
        p {
          font-size: 14px;
          margin-bottom: 16px;
        }
      }
    }
  }

  // responsive
  @media (max-width: 992px) {
    padding: 40px 0 !important;

    .amazonContentText {
      @include font-med($fs: 1.4rem);
    }

    &Grouping {
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }

    &Content {
      align-items: center;
      text-align: center;
    }

    &NewImg {
      max-width: 100% !important;
    }

    &Heading {
      max-width: 100%;
    }

    .amazonSellerSpecialText {
      margin-bottom: 20px !important;
      @include font-semi-bold($fs: 1.4rem !important);
    }
  }

  @media (max-width: 768px) {
    margin-top: 66px;

    .amazonSellerBannerImage {
      max-width: 100% !important;
    }

    .amazonsellerBox {
      .contentBox {
        .promoCode {
          @include font-med($fs: 1.8rem);
        }
      }
    }

    .offerModal .modalInfo {
      padding: 24px 16px;
    }

    .offerModal .modalInfo .infoImagWrapper {
      flex-direction: column;
      gap: 24px;
    }

    .offerModal .modalInfo .infoImagWrapper ul {
      width: 100%;
      padding-left: 16px;
    }
    .offerModal {
      padding: 0 26px;
      .modal {
        height: 640px;
        &Top {
          height: 22%;
          p {
            @include font-semi-bold($fs: 1.8rem);
          }
        }

        &Info {
          height: 78%;
          .infoImagWrapper {
            height: 405px;
            ul {
              overflow: scroll;
              li {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
      .offerButton {
        margin-top: 14px;
        margin-left: 0;
      }
    }

    .inputButtonBox {
      flex-direction: column;

      .select {
        width: 100% !important;
      }
    }

    &Heading {
      margin-bottom: 16px;
    }

    &ParaGraph {
      @include font-reg(1.4rem);
      margin-bottom: 20px;
    }

    &Image {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }
}

.amazonSellerBannerData {
  flex-basis: 46.8% !important;
}

.amazonSellerBannerParaData {
  max-width: 535px !important;
  margin-bottom: 32px !important;
}

.amazonSellerBannerImage {
  max-width: 46.4% !important;
}

.amazonSellerSpecialText {
  margin-bottom: 23px !important;
  @include font-semi-bold($fs: 2.4rem !important);
  line-height: 150%;
  color: $color-black-0;
}

.payLaterDirectRemoveTextTransform {
  text-transform: none !important;
}

.alertModal {
  .modal {
    width: 690px !important;
    position: relative;
    padding: 50px;
    border-radius: 20px;
    background: #fff;

    .modalTop {
      padding: 0;
      background: no-repeat;
      text-align: center;
      position: static;
      display: flex;
      flex-direction: column;
      p {
        // background: linear-gradient(
        //   90deg,
        //   #162770 -3.98%,
        //   #382361 19.85%,
        //   #8a357d 42.13%,
        //   #e64996 66.28%,
        //   #eb463d 88.55%,
        //   #ed6550 113.99%
        // );
        // background-clip: text;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        font-size: 28px;
        font-weight: bold;
        color: #222529;
      }
      > img {
        position: static;
        width: auto;
        max-width: fit-content;
        margin: auto;
        margin-bottom: 20px;
        &.close {
          right: -35px !important;
          top: 5px !important;
          width: 26px;
          position: absolute;
        }
      }
    }
    .modalInfo {
      background: transparent;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      gap: 24px;
      padding-top: 24px;
      p {
        color: #4e4e4e;
        font-size: 18px;
        line-height: 28px;
        font-weight: 500;
      }
    }
  }
}

.checkboxWrap {
  font-size: 14px;
  flex-wrap: wrap;
  img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }

  input {
    visibility: hidden;
    + img.checked {
      display: none;
    }
    &:checked {
      + img {
        display: inline-block;
        + img.unchecked {
          display: none;
        }
      }
    }
  }
  a {
    text-decoration: underline;
  }
}

.noBorder {
  border: none !important;
}
.desclimerSection {
  background-color: #faf5f9;
  display: flex;
  padding: 24px 42px;
  gap: 15px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  color: #9b3581;
  margin-bottom: 50px;
}
