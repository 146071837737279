@import "src/styles/variables.scss";

.terms {
  padding: 56px 0;

  &Heading {
    h1 {
      color: #4e4e4e;
      font-size: 40px;
      padding-bottom: 24px;
    }
  }

  &Label {
    h3 {
      color: #4e4e4e;
      font-size: 18px;
      font-weight: 600;
      line-height: 150%;
      padding: 16px 0 10px;
    }

    p {
      color: #4e4e4e;
      font-size: 16px;
      font-weight: 400;
      line-height: 160%;
      padding-bottom: 16px;
    }

    span {
      font-weight: 600;
      color: #000;
      margin-right: 10px;
      font-size: 16px;
    }

    label {
      color: #4e4e4e;
      font-size: 16px;
      font-weight: 400;
      line-height: 160%;
    }

    ol li {
      padding-left: 35px;
      font-weight: 600;
      color: #000;
      font-size: 16px;
    }
  }

  &Underline {
    border-bottom: 1px solid #000;
  }

  table,
  th,
  td {
    border: 1px solid #4e4e4e;
    border-collapse: collapse;
    padding: 2px 10px 10px;
    text-align: left;
    margin-bottom: 16px;
  }

  @media (max-width: 992px) {
    padding: 40px 0;

    &Heading {
      h1 {
        font-size: 20px;
        padding-bottom: 12px;
      }
    }

    &Label {
      h3 {
        font-size: 14px;
        padding: 8px 0 16px;
      }

      p {
        font-size: 14px;
      }

      span {
        font-weight: 600;
        color: #000;
        margin-right: 10px;
      }

      label {
        font-size: 14px;
      }

      ol {
        padding-left: 12px;

        li {
          font-size: 14px;
          padding-left: 0;
        }
      }
    }

    table,
    th,
    td {
      padding: 5px;
      font-size: 14px;
    }
  }
}
