@import "src/styles/variables.scss";

.wordpress {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  max-width: 960px;

  .author {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 16px;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
      overflow: hidden;
    }

    &Name {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 150%;

      em {
        font-style: normal;
        font-weight: 600;
      }
    }
  }

  .alignwide,
  .size-full {
    width: 100%;
    margin-bottom: 24px;
    position: relative;
  }

  &Title {
    margin-bottom: 16px !important;
  }

  figure {
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    margin: 40px 0;

    img {
      width: 100%;
      border-radius: 8px;
    }
  }

  img {
    width: auto;
  }

  .author {
    p {
      margin-bottom: 0 !important;
    }
  }

  h1 {
    font-size: 4.4em;
  }

  h2 {
    font-size: 4em;
  }

  h3 {
    font-size: 3.2em;
    margin-bottom: 20px;
  }

  h4 {
    font-size: 2.4em;
    margin-bottom: 12px;
  }

  h5 {
    font-size: 2em;
  }

  h6 {
    font-size: 1.6em;
  }

  p {
    font-size: 1.6rem;
    font-weight: 400 !important;
    line-height: 160%;
    margin-bottom: 24px;
  }

  a {
    color: $color-darkBlue-1;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 160%;
    text-decoration-line: underline;
  }

  strong,
  p,
  li,
  h1,
  h2,
  h4,
  h5,
  h6,
  h3 {
    align-self: flex-start;
    font-weight: 600;
  }

  strong {
    font-size: 1.6em;

    p {
      font-size: 1.6em;
    }

    ul,
    ol {
      margin: 20px 0;
      width: 100%;

      li {
        list-style: circle;
        font-size: 16px;
        margin-bottom: 16px;
        margin-left: 20px;
      }
    }

    blockquote {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      border-left: 10px solid #ccc;
      margin: 1.5em 10px;
      padding: 0.5em 10px;
      quotes: "\201C" "\201D" "\2018" "\2019";
      position: relative;
    }

    blockquote p {
      display: inline;
      width: 100%;
      text-align: left;
    }

    pre {
      width: 100%;
      border: 1px solid #646464;
      padding: 20px;
    }

    h1 {
      font-size: 3.2em;
    }

    h2 {
      font-size: 2.4em;
    }

    h3 {
      font-size: 2em;
    }

    h4 {
      font-size: 1.5em;
    }

    h5 {
      font-size: 1em;
    }

    h6 {
      font-size: 0.8em;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 700;
      margin-bottom: 25px !important;
    }

    p {
      font-size: 1.6em;
      line-height: 1.6em;
    }
  }

  hr {
    height: 24px;
    width: 100%;
    border: none;
    background-color: transparent;
  }

  @media (max-width: 768px) {
    figure {
      img {
        height: 100%;
      }
    }

    h1 {
      font-size: 2.4rem;
    }

    h2 {
      font-size: 2.2rem;
    }

    h3 {
      font-size: 2rem;
    }

    h4 {
      font-size: 1.8rem;
    }

    h5 {
      font-size: 1.6rem;
    }

    p {
      font-size: 1.4rem;
    }
  }
}

table {
  border: 1px solid #888;
  padding: 12px;
  border-collapse: collapse;
  border-spacing: 20px;
  border-radius: 8px;
}

td,
th {
  font-size: 1.6rem;
  border: 1px solid #888;
  padding: 10px;
  font-weight: 400 !important;
  line-height: 160%;
}

td strong:first-child {
  font-size: 1.3em;
}
