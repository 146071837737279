@import "src/styles/variables.scss";
@import "src/styles/common.scss";

.mission {
  &Image {
    width: 64px;
    height: 64px;
  }

  &Heading {
    text-align: inherit;
    padding: 16px 0 8px;
  }

  &Section {
    position: relative;
    flex-basis: 47.4%;
    padding: 40px;

    &:not(:last-of-type)::after {
      content: "";
      background: linear-gradient(
        90deg,
        #131d56 -1.65%,
        #33145d 19%,
        #63166a 38.29%,
        #a4267b 59.21%,
        #db327c 78.5%,
        #eb4b46 100.54%
      );
      width: 1px;
      height: 100%;
      display: block;
      position: absolute;
      right: -32px;
      top: 0;
    }

    p {
      color: $color-black-0;
      @include font-reg($fs: 1.6rem);
      line-height: 160%;
      max-width: 488px;
    }
  }

  &Container {
    display: flex;
    justify-content: center;
    gap: 64px;
  }

  @media (max-width: 992px) {
    &Image {
      width: 42px;
      height: 42px;
    }

    &Heading {
      padding: 8px 0 12px;
      text-align: inherit !important;
      @include font-semi-bold($fs: 2.4rem);
      line-height: 130%;
      margin-bottom: 0 !important;

      span {
        @include font-semi-bold($fs: 2.4rem);
      }
    }

    &Container {
      flex-direction: column;
      gap: 48px;
    }

    &Section {
      padding: 0;

      &:not(:last-of-type)::after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: unset;
        width: 100%;
        height: 2px;
        bottom: -12%;
      }

      p {
        @include font-reg($fs: 1.4rem);
        line-height: 150%;
        max-width: 100%;
      }
    }
  }
}

// Team Section

.team {
  &Heading {
    text-align: center;
  }

  &Content {
    p {
      @include font-reg($fs: 1.6rem);
      line-height: 160%;
      color: $color-grey-0;
      max-width: 835px;
      margin: 0 auto;
      text-align: center;
      padding: 0px 0 56px;
    }
  }

  &Container {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
  }

  .linear {
    padding: 1px;
    border-radius: 10px;
    background: linear-gradient(#181b4e, #9b3581, #e84680, #ec614e);
    flex-basis: 22%;
  }

  &Section {
    border-radius: 8px;
    background-color: white;
    flex-basis: 25.5%;
    cursor: pointer;
    transition: all 0.5s ease-out;
    height: 100%;
    // filter: grayscale(1);

    //TO DO &:hover {
    //   filter: grayscale(0);
    // }
  }

  &Profile {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 24px;
      height: 24px;
    }
  }

  &Position {
    span {
      @include font-semi-bold($fs: 1.8rem);
      line-height: 130%;
      color: $color-black-0;
      padding-bottom: 4px;
      display: block;
    }

    p {
      @include font-reg($fs: 1.6rem);
      line-height: 130%;
      color: $color-black-0;
    }
  }

  &ViewMore {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-top: 64px;
    cursor: pointer;

    p {
      @include font-med($fs: 1.6rem);
      background: $linear-gradient-1;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: block;
    }
  }

  @media (max-width: 992px) {
    &Container {
      flex-wrap: wrap;
    }

    &ViewMore {
      padding-top: 32px;
    }

    &Heading {
      span {
        @include font-semi-bold($fs: 2.4rem);
      }
    }

    .linear {
      flex-basis: 30%;
    }

    // TODO
    // &Section {
    //   filter: grayscale(0);
    //   max-width: 307px;
    //   min-height: 333px;
    // }

    &Content {
      p {
        @include font-reg($fs: 1.4rem);
        line-height: 150%;
        max-width: 100%;
        text-align: center;
        padding: 0 0 40px;
      }
    }
  }

  @media (max-width: 768px) {
    .linear {
      max-width: 309px;
    }

    &Container {
      flex-wrap: wrap;
      align-items: center;
      flex-direction: column;
      gap: 24px;
    }
  }
}

// Team Meet

.meetTeam {
  &Heading {
    text-align: center;
    padding-bottom: 24px;
    background: linear-gradient(
      90deg,
      #131d56 32.26%,
      #33145d 39.65%,
      #63166a 46.57%,
      #a4267b 54.06%,
      #db327c 60.98%,
      #eb4b46 68.87%
    ) !important;
    background-clip: text;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
  }

  &Section {
    p {
      @include font-reg($fs: 1.6rem);
      line-height: 160%;
      color: $color-grey-0;
      max-width: 835px;
      margin: 0 auto;
      text-align: center;
    }
  }

  &Image {
    max-width: 1000px;
    margin: 56px auto;
  }

  .meetTeamContent {
    max-width: 996px;
  }

  @media (max-width: 992px) {
    &Heading {
      padding-bottom: 16px;
    }

    &Section {
      p {
        @include font-reg($fs: 1.4rem);
        line-height: 150%;
        max-width: 100%;
      }
    }

    &Image {
      max-width: 1000px;
      margin: 40px auto;
    }

    .meetTeamContent {
      max-width: 100%;
    }
  }
}

// Founders Section

.ourFounder {
  background: linear-gradient(
      90deg,
      rgba(19, 29, 86, 0.08) -1.65%,
      rgba(51, 20, 93, 0.08) 19%,
      rgba(99, 22, 106, 0.08) 38.29%,
      rgba(164, 38, 123, 0.08) 59.21%,
      rgba(219, 50, 124, 0.08) 78.5%,
      rgba(235, 75, 70, 0.08) 100.54%
    ),
    #fff !important;

  &Container {
    display: flex;
    gap: 56px;
    padding-top: 32px;
  }

  &Image {
    display: flex;
    gap: 24px;

    &Card {
      position: relative;
      cursor: pointer;

      &LinkedIn {
        position: absolute;
        right: 24px;
        top: 28px;
      }
    }
  }

  &Description {
    max-width: 446px;
    align-self: center;

    &Color {
      @include font-bold($fs: 1.8rem);
      background: linear-gradient(
        92deg,
        #181b4e 2.03%,
        #9b3581 12.49%,
        #e84680 22.25%,
        #ec614e 33.87%
      ) !important;
      background-clip: text;
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important;
    }

    p {
      color: $color-black-0;
      @include font-reg($fs: 1.8rem);
      line-height: 160%;
    }

    &Content {
      padding-top: 16px;

      &Bold {
        color: $color-black-0;
        @include font-semi-bold($fs: 1.8rem);
      }
    }
  }

  @media (max-width: 1160px) {
    &Container {
      flex-direction: column;
      padding-top: 16px;
      gap: 24px;
    }

    &Image {
      flex-wrap: wrap;
      justify-content: center;

      img {
        // TODO width: 100%;
      }
    }

    &Description {
      max-width: 100%;
      align-self: inherit;

      &Color {
        @include font-bold($fs: 1.4rem);
      }

      p {
        @include font-reg($fs: 1.4rem);
        line-height: 150%;
      }

      &Content {
        &Bold {
          @include font-semi-bold($fs: 1.4rem);
          line-height: 150%;
        }
      }
    }
  }
}
