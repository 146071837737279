@import "src/styles/variables.scss";
@import "src/styles/common.scss";

.certificates {
  margin-top: 80px;

  &Modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: rgba(1, 1, 1, 0.5);
    @include d-flex(center);
    align-items: center;

    &Content {
      color: white;

      .certificate {
        .crossRound {
          position: absolute;
          margin-left: 13px;
          cursor: pointer;
        }
      }
    }
  }

  &Container {
    .certificateDetails {
      padding-top: 50px;
      padding-bottom: 80px;
      @include d-flex(center);
      flex-direction: column;
      gap: 48px;

      &Top {
        @include d-flex(center);
        align-items: flex-start;
        flex-direction: column;
        gap: 12px;

        .iconBox {
          height: 46px;
          width: 46px;
          img {
            height: 100%;
            width: 100%;
          }
        }

        h1 {
          font-family: Montserrat;
          font-size: 36px;
          font-style: normal;
          font-weight: 600;
          line-height: 130%;
          text-transform: capitalize;
          background: var(
            --Primary-Brand-Gradient,
            linear-gradient(
              90deg,
              #131d56 -1.66%,
              #33145d 26.58%,
              #63166a 53.17%,
              #a4267b 72.03%,
              #db327c 87.36%,
              #eb4b46 103.24%
            )
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .paraBox {
          @include d-flex();
          flex-direction: column;
          gap: 50px;

          p {
            color: #4e4e4e;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%;
          }
        }
      }

      &Bottom {
        width: 100%;
        .certificateBox {
          position: relative;
          @include d-flex(center);
          align-items: center;

          .modal {
            @include d-flex(center);
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(13, 11, 65, 0.45);
            display: none;

            button {
              cursor: pointer;
              @include d-flex();
              align-items: center;
              gap: 16px;
              padding: 16px 24px;
              border-radius: 50px;
              border: 1px solid var(--Color, #fff);
              background: rgba(255, 255, 255, 0.08);
              backdrop-filter: blur(38.5px);
              color: #fff;
              @include font-reg($fs: 1.8rem);
              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              line-height: 130%;
              text-transform: capitalize;
            }
          }

          &:hover {
            .modal {
              display: flex;
              opacity: 1;
              visibility: visible;
              transition:
                opacity 1s ease,
                visibility 1s ease;
              -webkit-transition:
                opacity 1s ease,
                visibility 1s ease;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .certificates {
    margin-top: 67px;
    &Container {
      .certificateDetails {
        padding-top: 40px;
        padding-bottom: 40px;
        gap: 24px;

        .certificateBox {
          &:hover {
            .modal {
              display: none;
            }
          }
        }

        &Top {
          h1 {
            font-size: 24px;
          }
          .paraBox {
            gap: 25px;

            p {
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
