@import "src/styles/variables.scss";

.blogBanner {
  &Article {
    @include d-flex();
    gap: 8px;
    width: fit-content;

    span {
      color: #19147b !important;
      @include font-med(1.6rem);
      cursor: pointer;
      line-height: normal;
    }

    img {
      cursor: pointer;
    }
  }

  &Border {
    padding: 1px;
    border-radius: 10px;

    // &:nth-child(1) {
    //   flex-basis: 64.3%;
    // }

    // &:nth-child(2) {
    //   flex-basis: 33%;
    // }

    // &:nth-child(3) {
    //   flex-basis: 49%;
    // }

    // &:nth-child(4) {
    //   flex-basis: 49%;
    // }
    // &:hover {
    //   background: $linear-gradient-3;
    // }
  }

  &Platform {
    border-radius: 10px;
    border: 1px solid rgba(25, 20, 123, 0.08);
    background: url("../../assets/images/header/Blog-1.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 20px;
    @include d-flex(space-between);
    // flex-direction: column;
    min-height: 237px;
    align-items: stretch;
    flex-basis: 64.3%;
    // cursor: pointer;
  }

  .secondCard {
    min-height: 237px;
    flex-basis: 33%;
    background: #c5cefc !important;

    .blogBannerButton {
      border: 1px solid #19147b !important;

      span {
        color: #19147b !important;
      }
    }

    h3 {
      color: #19147b !important ;
    }

    span {
      color: #2e173b !important;
    }
  }

  .thirdCard {
    background: #fde2f0 !important;
    min-height: 237px;
    flex-basis: 33%;

    h3 {
      margin: 0 0 14px 0 !important;
      color: #9b3581 !important;
    }

    span {
      color: #2e173b !important;
    }
  }

  .fourthCard {
    background: url("../../assets/images/header/Blog-2.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &Schedule {
    @include d-flex();
    gap: 16px;
  }

  &Calendar {
    @include d-flex();
    gap: 6px;

    span {
      color: #e8e8e4;
      @include font-reg(1.4rem);
      line-height: normal;
    }
  }

  &Header {
    @include d-flex(space-between);
    flex-direction: column;
    align-items: flex-start;
    gap: 22px;

    h3 {
      @include font-semi-bold(2.2rem);
      color: #fff;
      line-height: 120%;
      margin: 14px 0;
      max-width: 400px;
    }
  }

  &Cards {
    @include d-flex(space-between);
    gap: 24px;
    flex-wrap: wrap;
    align-items: stretch;
  }

  &Button {
    @include d-flex();
    padding: 10px 16px;
    border: 1px solid #fff;
    background: transparent;
    border-radius: 4px;
    width: fit-content;
    gap: 10px;
    transition: all 0.6s ease-in;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      transition: all 0.5s ease-in;

      img {
        transform: translate(6px);
        transition: all 0.5s ease;
      }
    }

    img {
      transition: all 0.5s ease;
    }

    span {
      color: #fff;
      @include font-med(1.3rem);
    }
  }

  @media (max-width: 992px) {
    &Platform {
      flex-direction: column;
      width: 100%;
      gap: 24px;
      max-height: none;
    }

    &Cards {
      flex-direction: column;
      align-items: stretch;
    }

    // &Border {
    //   &:nth-child(1) {
    //     flex-basis: 100%;
    //   }

    //   &:nth-child(2) {
    //     flex-basis: 100%;
    //   }

    //   &:nth-child(3) {
    //     flex-basis: 100%;
    //   }

    //   &:nth-child(4) {
    //     flex-basis: 100%;
    //   }
    // }

    &Header {
      h3 {
        @include font-semi-bold(1.8rem);
      }
    }
  }
}

.disableAudio {
  pointer-events: none;
  opacity: 0.6;
}
