@import "src/styles/variables.scss";

.blogs {
  margin-top: 80px !important;
  padding: 100px 0 !important;
  background: linear-gradient(
    90deg,
    rgba(22, 39, 112, 0.05) -3.98%,
    rgba(56, 35, 97, 0.05) 19.05%,
    rgba(138, 53, 125, 0.05) 40.57%,
    rgba(230, 73, 150, 0.05) 63.91%,
    rgba(235, 70, 61, 0.05) 85.43%,
    rgba(237, 101, 80, 0.05) 110%
  ) !important;

  &Heading {
    h1 {
      background: var(
        --G-2,
        linear-gradient(
          90deg,
          #162770 -3.98%,
          #382361 19.05%,
          #8a357d 40.57%,
          #e64996 63.91%,
          #eb463d 85.43%,
          #ed6550 110%
        )
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-bottom: 24px;
      @include font-semi-bold($fs: 5rem);
      letter-spacing: 1px;
      text-align: center;
    }

    &ParaGraph {
      color: $color-grey-0;
      font-size: 1.8rem;
      font-weight: 300;
      line-height: 150%;
      text-align: center;
      max-width: 930px;
      margin: 0 auto;
    }
  }

  &Card {
    padding: 40px 0 100px;

    &Modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      overflow: hidden;
      align-items: center;
      z-index: 999;
    }

    &PopUpContent {
      @include d-flex(space-between);
      align-items: flex-start;
      gap: 24px;
      border-radius: 8px;
      background: #fff;
      box-shadow: 4px 8px 40px 0px rgba(0, 0, 0, 0.09);
      padding: 40px;
    }

    &PopUpData {
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    &PopUpFirstData {
      color: $color-grey-0;
      @include font-med(1.6rem);
      line-height: 150%;
      cursor: pointer;

      &:hover {
        border-radius: 4px;
        padding: 8px 16px;
        background: $linear-gradient-2;

        span {
          background: $linear-gradient-6;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      &:last-of-type {
        margin: 0;
      }
    }

    &Heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 40px;

      &Para {
        font-family: EchoesSansMedium;
        @include font-med($fs: 2.4rem);
        line-height: 130%;
        color: $color-black-0;
        @include d-flex(flex-start);
        gap: 16px;
        .clearFilters {
          @include d-flex(center);
          color: var(--accent-violet, #87a0e5);
          gap: 6px;
          /* Content/Text 2 */
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%; /* 25.6px */
          cursor: pointer;
        }
      }
    }

    &Button {
      position: relative;
      button {
        color: $color-darkBlue-0;
        @include font-med($fs: 1.4rem);
        padding: 10px 18px;
        display: flex;
        cursor: pointer;
        gap: 10px;
        align-items: center;
        border-radius: 4px;
        border: 1px solid $color-gray-3;
        background: #fff;

        &:hover {
          border: 1px solid #ffe0f0;
          background: #fffafc;
        }
      }
    }

    &Container {
      display: flex;
      gap: 40px;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    &Section {
      border-radius: 8px;
      border: 1px solid var(--gray-shade-4, #f0f0f0);
      background: #fff;
      box-shadow: 2px 2px 30px 0px rgba(0, 0, 0, 0.04);
      max-width: 373px;
      cursor: pointer;
    }

    &Image {
      img {
        border-radius: 8px 8px 0 0;
        max-width: 373px;
      }
    }

    &Contents {
      padding: 16px;
    }

    &Profile {
      display: flex;
      gap: 8px;
      padding-top: 16px;

      &Content {
        span {
          @include font-med($fs: 1.6rem);
          line-height: 160%;
          color: $color-black-0;
          padding-bottom: 4px;
          display: block;
        }

        p {
          @include font-reg($fs: 1.2rem);
          line-height: 150%;
          color: $color-grey-0;
        }
      }
    }

    &ViewMore {
      display: flex;
      justify-content: center;
      gap: 10px;
      align-items: center;
      cursor: pointer;

      span {
        @include font-med($fs: 1.6rem);
        background: var(
          --G-4,
          linear-gradient(
            90deg,
            #131d56 -1.66%,
            #33145d 26.58%,
            #63166a 53.17%,
            #a4267b 72.03%,
            #db327c 87.36%,
            #eb4b46 103.24%
          )
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &Filters {
      @include d-flex(flex-start);
      align-items: center;
      width: 100%;
      margin: 30px 0px 0 0;
      padding-bottom: 30px;
      background-color: $color-white-0;
      gap: 14px;
      overflow: auto;
      box-shadow: 0px 1px 0 0 #e8e8e4;

      &::-webkit-scrollbar {
        display: block;
        height: 2px;
      }

      .filterItem {
        @include d-flex(center);
        padding: 8px 16px;
        cursor: pointer;
        color: $color-grey-0;
        text-align: left;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        white-space: nowrap;
        border-radius: 8px;
        border: none;
        background-color: $color-white-1;
      }

      .filterItemSelected {
        background-color: $color-darkBlue-1;
        color: $color-white-0;
      }
    }
  }

  @media (max-width: 992px) {
    padding: 64px 0px !important;
    margin-top: 66px !important;

    &Heading {
      h1 {
        padding-bottom: 16px;
        @include font-semi-bold($fs: 2.8rem);
      }

      &Para {
        font-size: 1.4rem;
        font-weight: 300;
        max-width: 100%;
      }
    }

    &Card {
      padding: 24px 0 40px;

      &Image {
        img {
          border-radius: 8px 8px 0 0;
          max-width: 100%;
        }
      }

      &Container {
        flex-direction: column;
      }

      &Heading {
        padding-bottom: 24px;
        gap: 24px;

        &Para {
          @include font-med($fs: 1.8rem);
        }
      }

      &Button {
        button {
          @include font-med($fs: 1.4rem);
          padding: 6px 12px;
          gap: 6px;
        }
      }

      &Profile {
        &Content {
          span {
            @include font-med($fs: 1.4rem);
          }

          p {
            @include font-reg($fs: 1rem);
          }
        }
      }

      &Filters {
        margin: 20px 0 0 0;
        padding-bottom: 20px;
        overflow: auto;
        .filterItem {
          flex-basis: 100%;
        }
      }

      &ViewMore {
        padding-top: 32px;
        gap: 6px;

        &Text {
          @include font-med($fs: 1.4rem);
        }
      }
    }
  }
}
