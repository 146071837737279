@import "/src/styles/variables.scss";

.contactUs {
  background: $linear-gradient-2 !important;
  margin-top: 80px;

  &Button {
    @include d-flex(center);
    height: 43px !important;
  }

  &Page {
    border-radius: 8px;
    background: #fff;
    box-shadow: 4px 8px 50px 0px rgba(0, 0, 0, 0.03);
    padding: 40px;

    h2 {
      margin-bottom: 6px !important;
    }

    p {
      color: $color-grey-0;
      @include font-reg(1.6rem);
      text-align: center;
      line-height: 160%;
    }
  }

  // form
  &Form {
    // @include d-flex(flex-start);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 40px;
    margin: 0 0 24px;

    &:first-of-type {
      margin-top: 40px;
    }

    &Select {
      &:focus {
        color: $color-black-0 !important;
      }
    }

    &Dropdown {
      position: relative;
      cursor: pointer;

      &::after {
        content: url("../../assets/images/footer/arrowDown.svg");
        position: absolute;
        top: 23px;
        right: 15px;
        pointer-events: none;
        cursor: pointer;
      }
    }

    &Input {
      color: $color-black-0;
      -webkit-appearance: none;
      background: $color-white-0;
      padding: 10px 14px;
      border-radius: 4px;
      border: 1px solid $color-gray-3;
      @include font-reg(1.6rem);
      line-height: 160%;
      width: 100%;
      outline: none;
      margin-top: 8px;
      display: inline-block;
      cursor: pointer;

      &Error {
        color: red;
        @include font-reg(1.4rem);
        margin-top: 8px;
      }

      &::placeholder {
        color: $color-grey-1;
      }
    }

    > div {
      width: calc(50% - 20px);
    }
  }

  label {
    color: $color-black-0;
    @include font-med(1.6rem);
    line-height: 160%;
  }

  &TextArea {
    color: $color-black-0;
    padding: 10px 14px;
    border-radius: 4px;
    border: 1px solid $color-gray-3;
    @include font-reg(1.6rem);
    line-height: 160%;
    width: 100%;
    resize: none;
    outline: none;
    margin: 8px 0 40px;
    padding: 10px;
    height: 96px;

    &::placeholder {
      color: $color-grey-1;
    }
  }

  &Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $linear-gradient-2 !important;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    z-index: 999;
  }

  &PopUp {
    border-radius: 8px;
    background: #fff;
    box-shadow: 4px 8px 50px 0px rgba(0, 0, 0, 0.03);
    max-width: 527px;
    margin: 0 auto;
    padding: 56px 64px;
    text-align: center;
    z-index: 2;

    &Text {
      color: $color-black-0;
      @include font-semi-bold(1.6rem);
      line-height: 160%;
    }

    h5 {
      margin: 24px 0 6px;
    }
  }

  // responsive
  @media (max-width: 992px) {
    margin-top: 66px;

    &Page {
      padding: 34px;
    }

    // form
    &Form {
      flex-direction: column;
      gap: 24px;
      margin: 24px 0 24px;

      &Input {
        @include font-reg(1.4rem);
      }

      > div {
        width: 100%;
      }
    }

    &TextArea {
      @include font-reg(1.4rem);
      height: 100%;
    }

    &Button {
      width: 100%;
      justify-content: center;
    }

    &PopUp {
      max-width: 300px;
      margin: 0 auto;
      padding: 20px 30px;

      &Text {
        @include font-semi-bold(1.4rem);
      }

      h5 {
        margin: 20px 0 6px;
      }
    }
  }
}

.inputRequired {
  label {
    &::after {
      content: "*";
      color: red;
      margin-left: 5px;
    }
  }
}

select:invalid,
select option[value=""] {
  color: #888;
}
