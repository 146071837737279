@import "/src/styles/variables.scss";

.useCase {
  background: #f9f9f9;
  padding: 80px 0;

  &Card {
    flex-direction: row;
    gap: 24px;
    align-items: stretch;

    img {
      height: 60px;
    }

    &Title {
      h3 {
        max-width: 100%;
      }

      &Para {
        padding-bottom: 32px;
      }
    }

    &Total {
      height: 100%;
    }
  }

  &CustomListStyles {
    margin-left: 18px;

    li {
      margin-bottom: 4px;
      font-size: 16px;
      color: #2e173b;
      line-height: 160%;
      list-style-type: disc;
    }
  }

  @media (max-width: 992px) {
    padding: 40px 0;

    &Card {
      flex-direction: column;

      &Title {
        &Para {
          padding-bottom: 24px;

          p {
            max-width: 100%;
          }
        }
      }

      &Linear {
        width: 100%;
      }
    }

    &CustomListStyles {
      li {
        font-size: 14px;
      }
    }
  }
}
