@import "src/styles/variables.scss";
@import "src/styles/common.scss";

.company {
  margin-bottom: 50px !important;
  text-align: center;
  text-transform: capitalize;

  &Bg {
    padding: 50px 0 90px;
    border-top: 1px solid var(--gray-shade-4, #f0f0f0);
  }

  &MarqueeImg {
    white-space: nowrap;
    overflow: hidden;

    img {
      margin-right: 90px;
    }
  }

  &SliderGradient {
    font-size: 4.4rem !important;
    background: $linear-gradient-18;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 992px) {
    margin-bottom: 16px !important;

    @include font-med(1.6rem);
    margin-bottom: 34px;
    text-align: center;

    &SliderGradient {
      font-size: 2.4rem !important;
    }

    &Bg {
      padding: 40px 0 58px;
    }

    &MarqueeImg {
      img {
        margin-right: 42.86px;
      }
    }
  }
}
